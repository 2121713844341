import React, { useState } from "react"
import { Link } from "gatsby"
import { login, logout, isAuthenticated, getUser } from "../utils/auth"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LoginPage = () => {
  const [user, setUser] = useState({});

  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  getUser(setUser);

  return (<Layout>
    <SEO title="Login Page" />
    <p>Hi, {user.name ? user.name : "friend"}!</p>
    <a
      href="#logout"
      onClick={async e => {
        await logout();
        e.persist();
      }}
    >
      Log Out
    </a>
    <Link style={{padding: '20px'}} to="/">Go back to the homepage</Link>
  </Layout>)
}

export default LoginPage


